export default function (product) {
  const inclusions = product["inclusions"].split(" & ");

  let html =
    '<table><thead><tr><th colspan="4">Inclusions</th></tr></thead><tbody>';

  for (let inclusion of inclusions) {
    html =
      html +
      `
        <tr>
        <td colspan="1"></td>
        <td colspan="3">${inclusion}</td>
       </tr>
        `;
  }

  html = html + "</tbody></table><br /><br />";
  html =
    html +
    `
    <table>
    <thead>
      <tr>
        <th></th>
        <th>Shirt</th>
        <th>Dupatta</th>
        <th>Bottom</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><em>Fabric</em></td>
        <td>${product["shirt_fabric"]}</td>
        <td>${product["dupatta_fabric"]}</td>
        <td>${product["bottom_fabric"]}</td>
      </tr>
      <tr>
        <td><em>Base</em></td>
        <td>${product["shirt_base"]}</td>
        <td>${product["dupatta_base"]}</td>
        <td>${product["bottom_base"]}</td>
      </tr>
      <tr>
        <td><em>Kind</em></td>
        <td>${product["shirt_kind"]}</td>
        <td>${product["dupatta_kind"]}</td>
        <td>${product["bottom_kind"]}</td>
      </tr>
      <tr>
        <td><em>Length</em></td>
        <td>${product["shirt_length"]}</td>
        <td>${product["dupatta_length"]}</td>
        <td>${product["bottom_length"]}</td>
      </tr>
      <tr>
        <td><em>Width</em></td>
        <td>${product["shirt_width"]}</td>
        <td>${product["dupatta_width"]}</td>
        <td>${product["bottom_width"]}</td>
      </tr>
      <tr>
        <td><em>Work</em></td>
        <td>${product["shirt_value"]}</td>
        <td>${product["dupatta_value"]}</td>
        <td>${product["bottom_value"]}</td>
      </tr>
      <tr>
      <td><em>Note</em></td>
      <td colspan="3">A 5% variation in the length/width of the fabric is to be expected. All of our sets are produced specifically for our brand on order and small variations are inherent to the process of manually cutting fabric.</td>
      </tr>
    </tbody>
  </table>
  <br /><br />
    `;

  const cleaning =
    product["washing"] === "Dry Clean"
      ? `
    This product must always be dry cleaned. Washing is not recommended.`
      : `Always get the product dry cleaned before first use. The product may be hand washed in a solution of warm water and Easy. Use of hot water and hard detergents is not recommended. Washing in a machine is not suitable.`;

  html =
    html +
    `
      <table>
    <thead>
      <th>Maintenance Guide</th>
    </thead>
    <tbody>
      <tr>
        <td>
          ${cleaning}
        </td>
      </tr>
    </tbody>
  </table><br /><br />`;
  return html;
}
