import React, { Component } from "react";
import axios from "axios";

import AttributeSelect from "./attributeSelect";
import * as Attributes from "./attributes";
import makeProductObject from "./productObject";

const cloudName = "zyanya";
const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sku: "",
      mrp: 0,
      style: "NA",
      inclusions: "NA",
      maintenance: "NA",
      shirt_fabric: "NA",
      shirt_base: "NA",
      shirt_kind: "NA",
      shirt_value: "NA",
      shirt_length: "NA",
      shirt_width: "NA",
      dupatta_fabric: "NA",
      dupatta_base: "NA",
      dupatta_kind: "NA",
      dupatta_value: "NA",
      dupatta_length: "NA",
      dupatta_width: "NA",
      bottom_fabric: "NA",
      bottom_base: "NA",
      bottom_kind: "NA",
      bottom_value: "NA",
      bottom_length: "NA",
      bottom_width: "NA",
      uploadingImages: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleImageSelector = (event) => {
    this.setState({
      uploadingImages: true,
    });
    const imagePromises = [];
    for (let file of event.target.files) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "osgwvcrq");
      formData.append("multiple", true);
      formData.append("public_id", file.name);

      imagePromises.push(axios.post(url, formData));
    }

    Promise.all(imagePromises).then((responses) => {
      const images = [];
      for (let response of responses) {
        images.push(response.data.secure_url);
      }
      this.setState({
        images: images,
        uploadingImages: false,
      });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const products = makeProductObject(this.state);

    const url = "https://s.zyanya.in/products/new";

    const postPromises = [];

    for (let product of products) {
      postPromises.push(
        axios.post(url, product, {
          headers: {
            "X-Zyanya-Auth-Key": "wPfuGDQwGjDePFyDdySznuBz",
          },
        })
      );
    }

    Promise.all(postPromises).then((responses) => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.setState({
        sku: "",
        mrp: 0,
        style: "NA",
        inclusions: "NA",
        maintenance: "NA",
        shirt_fabric: "NA",
        shirt_base: "NA",
        shirt_kind: "NA",
        shirt_value: "NA",
        shirt_length: "NA",
        shirt_width: "NA",
        dupatta_fabric: "NA",
        dupatta_base: "NA",
        dupatta_kind: "NA",
        dupatta_value: "NA",
        dupatta_length: "NA",
        dupatta_width: "NA",
        bottom_fabric: "NA",
        bottom_base: "NA",
        bottom_kind: "NA",
        bottom_value: "NA",
        bottom_length: "NA",
        bottom_width: "NA",
        uploadingImages: false,
      });
    });
  };

  render() {
    return (
      <form className="addProductForm">
        <div className="addProductField">
          <label htmlFor="sku" className="addProductFieldLabel">
            Product SKU
          </label>
          <input
            type="text"
            id="sku"
            name="sku"
            className="input_text"
            onChange={this.handleInputChange}
            value={this.state["sku"]}
          ></input>
        </div>
        <div className="addProductField">
          <label htmlFor="price" className="addProductFieldLabel">
            MRP
          </label>
          <input
            type="number"
            id="mrp"
            name="mrp"
            className="input_number"
            onChange={this.handleInputChange}
            value={this.state["mrp"]}
          />
        </div>
        <div className="addProductField">
          <AttributeSelect
            onChange={this.handleInputChange}
            label="Style"
            attribute="style"
            options={Attributes.styles}
            value={this.state["style"]}
          ></AttributeSelect>
        </div>
        <div className="addProductField">
          <AttributeSelect
            onChange={this.handleInputChange}
            label="Inclusions"
            attribute="inclusions"
            options={Attributes.inclusions}
            value={this.state["inclusions"]}
          ></AttributeSelect>
        </div>
        <div className="addProductField">
          <AttributeSelect
            onChange={this.handleInputChange}
            label="Maintenance"
            attribute="maintenance"
            options={Attributes.maintenance}
            value={this.state["maintenance"]}
          ></AttributeSelect>
        </div>
        <fieldset className="addProductFieldSet">
          <legend>Shirt</legend>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Fabric"
              attribute="shirt_fabric"
              options={Attributes.fabrics}
              value={this.state["shirt_fabric"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Base"
              attribute="shirt_base"
              options={Attributes.base}
              value={this.state["shirt_base"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Kind"
              attribute="shirt_kind"
              options={Attributes.kinds}
              value={this.state["shirt_kind"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Value Addition"
              attribute="shirt_value"
              options={Attributes.va}
              value={this.state["shirt_value"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Length"
              attribute="shirt_length"
              options={Attributes.length}
              value={this.state["shirt_length"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Width"
              attribute="shirt_width"
              options={Attributes.width}
              value={this.state["shirt_width"]}
            ></AttributeSelect>
          </div>
        </fieldset>
        <fieldset className="addProductFieldSet">
          <legend>Dupatta</legend>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Fabric"
              attribute="dupatta_fabric"
              options={Attributes.fabrics}
              value={this.state["dupatta_fabric"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Base"
              attribute="dupatta_base"
              options={Attributes.base}
              value={this.state["dupatta_base"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Kind"
              attribute="dupatta_kind"
              options={Attributes.kinds}
              value={this.state["dupatta_kind"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Value Addition"
              attribute="dupatta_value"
              options={Attributes.va}
              value={this.state["dupatta_value"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Length"
              attribute="dupatta_length"
              options={Attributes.length}
              value={this.state["dupatta_length"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Width"
              attribute="dupatta_width"
              options={Attributes.width}
              value={this.state["dupatta_width"]}
            ></AttributeSelect>
          </div>
        </fieldset>
        <fieldset className="addProductFieldSet">
          <legend>Bottom</legend>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Fabric"
              attribute="bottom_fabric"
              options={Attributes.fabrics}
              value={this.state["bottom_fabric"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Base"
              attribute="bottom_base"
              options={Attributes.base}
              value={this.state["bottom_base"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Kind"
              attribute="bottom_kind"
              options={Attributes.kinds}
              value={this.state["bottom_kind"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Value Addition"
              attribute="bottom_value"
              options={Attributes.va}
              value={this.state["bottom_value"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Length"
              attribute="bottom_length"
              options={Attributes.length}
              value={this.state["bottom_length"]}
            ></AttributeSelect>
          </div>
          <div className="addProductField">
            <AttributeSelect
              onChange={this.handleInputChange}
              label="Width"
              attribute="bottom_width"
              options={Attributes.width}
              value={this.state["bottom_width"]}
            ></AttributeSelect>
          </div>
        </fieldset>
        <fieldset className="addProductFieldSet">
          <legend>Product Images</legend>
          <input
            type="file"
            name="images"
            id="images"
            className="input_file"
            onChange={this.handleImageSelector}
            multiple
            accept="image/jpeg,image/png"
          />
        </fieldset>
        <div className="container_button_addProduct">
          <button
            className="button_addProduct"
            onClick={this.handleSubmit}
            disabled={this.state.uploadingImages || !this.state.sku}
          >
            {this.state.uploadingImages ? "Uploading Images" : "Add Product"}
          </button>
        </div>
      </form>
    );
  }
}

export default AddProduct;
