import React from "react";

const AttributeSelect = (props) => {
  let attributeSelect = null;
  let label = props.label ? props.label : "";
  if (props.attribute && props.options instanceof Array) {
    if (props.options.length > 0) {
      attributeSelect = (
        <React.Fragment>
          <label htmlFor={props.attribute} className="addProductFieldLabel">
            {label}
          </label>
          <select
            id={props.attribute}
            name={props.attribute}
            className="input_select"
            onChange={props.onChange}
            value={props.value}
          >
            {props.options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        </React.Fragment>
      );
    }
  }
  return <React.Fragment>{attributeSelect}</React.Fragment>;
};

export default AttributeSelect;
