import React from "react";
import Logo from "../../resources/logo.png";

const Header = () => {
  return (
    <div className="header">
      <div className="container_logo">
        <img src={Logo} alt="Zyanya Logo" className="logo"></img>
      </div>
    </div>
  );
};

export default Header;
