import React from "react";
import "./App.css";

import Header from "./components/navigation/header";
import MainPanel from "./components/panel/mainPanel";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <MainPanel></MainPanel>
    </div>
  );
}

export default App;
