import React from "react";

import AddProduct from "../product/addProduct";

const MainPanel = (props) => {
  return (
    <div className="mainPanel">
      <div className="products"></div>
      <div className="addProduct">
        <AddProduct></AddProduct>
      </div>
    </div>
  );
};

export default MainPanel;
