import getProductHTML from "./productHTML";

export default function (source) {
  const result = [];
  const images = source.images;
  if (images instanceof Array) {
    for (let i = 0; i < images.length; i++) {
      if (i === 0) {
        const product = {};

        const sku = source.sku;

        product["Handle"] = sku;
        product["Title"] = source["shirt_fabric"] + " - " + source["style"];
        product["Body (HTML)"] = getProductHTML(source);
        product["Vendor"] = "Self";
        product["Type"] = source["style"];
        product["Tags"] = source["shirt_fabric"];
        product["Published"] = "TRUE";
        product["Option1 Name"] = "Title";
        product["Option1 Value"] = "Default Title";
        product["Option2 Name"] = "";
        product["Option2 Value"] = "";
        product["Option3 Name"] = "";
        product["Option3 Value"] = "";
        product["Variant SKU"] = sku;
        product["Variant Grams"] = 750;
        product["Variant Inventory Tracker"] = "shopify";
        product["Variant Inventory Qty"] = 1;
        product["Variant Inventory Policy"] = "deny";
        product["Variant Fulfillment Service"] = "manual";
        product["Variant Price"] = source["mrp"];
        product["Variant Compare At Price"] = source["mrp"];
        product["Variant Requires Shipping"] = "TRUE";
        product["Variant Taxable"] = "TRUE";
        product["Variant Barcode"] = sku;
        product["Image Src"] = images[i];
        product["Image Position"] = 1;
        product["Image Alt Text"] = "Indian Fabric " + source["inclusions"];
        product["Gift Card"] = "FALSE";
        product["SEO Title"] = source["shirt_fabric"] + " - " + source["style"];
        product["SEO Description"] = "";
        product["Google Shopping / Google Product Category"] = "";
        product["Google Shopping / Gender"] = "";
        product["Google Shopping / Age Group"] = "";
        product["Google Shopping / MPN"] = "";
        product["Google Shopping / AdWords Grouping"] = "";
        product["Google Shopping / AdWords Labels"] = "";
        product["Google Shopping / Condition"] = "";
        product["Google Shopping / Custom Product"] = "";
        product["Google Shopping / Custom Label 0"] = "";
        product["Google Shopping / Custom Label 1"] = "";
        product["Google Shopping / Custom Label 2"] = "";
        product["Google Shopping / Custom Label 3"] = "";
        product["Google Shopping / Custom Label 4"] = "";
        product["Variant Image"] = "";
        product["Variant Weight Unit"] = "";
        product["Variant Tax Code"] = "";
        product["Cost per item"] = "";
        product["Collection"] =
          source["shirt_fabric"] + " - " + source["style"];

        result.push(product);
      } else {
        const product = {};

        const sku = source.sku;

        product["Handle"] = sku;
        product["Title"] = "";
        product["Body (HTML)"] = "";
        product["Vendor"] = "";
        product["Type"] = "";
        product["Tags"] = "";
        product["Published"] = "";
        product["Option1 Name"] = "";
        product["Option1 Value"] = "";
        product["Option2 Name"] = "";
        product["Option2 Value"] = "";
        product["Option3 Name"] = "";
        product["Option3 Value"] = "";
        product["Variant SKU"] = "";
        product["Variant Grams"] = "";
        product["Variant Inventory Tracker"] = "";
        product["Variant Inventory Qty"] = "";
        product["Variant Inventory Policy"] = "";
        product["Variant Fulfillment Service"] = "";
        product["Variant Price"] = "";
        product["Variant Compare At Price"] = "";
        product["Variant Requires Shipping"] = "";
        product["Variant Taxable"] = "";
        product["Variant Barcode"] = "";
        product["Image Src"] = images[i];
        product["Image Position"] = "";
        product["Image Alt Text"] = "";
        product["Gift Card"] = "";
        product["SEO Title"] = "";
        product["SEO Description"] = "";
        product["Google Shopping / Google Product Category"] = "";
        product["Google Shopping / Gender"] = "";
        product["Google Shopping / Age Group"] = "";
        product["Google Shopping / MPN"] = "";
        product["Google Shopping / AdWords Grouping"] = "";
        product["Google Shopping / AdWords Labels"] = "";
        product["Google Shopping / Condition"] = "";
        product["Google Shopping / Custom Product"] = "";
        product["Google Shopping / Custom Label 0"] = "";
        product["Google Shopping / Custom Label 1"] = "";
        product["Google Shopping / Custom Label 2"] = "";
        product["Google Shopping / Custom Label 3"] = "";
        product["Google Shopping / Custom Label 4"] = "";
        product["Variant Image"] = "";
        product["Variant Weight Unit"] = "";
        product["Variant Tax Code"] = "";
        product["Cost per item"] = "";
        product["Collection"] = "";

        result.push(product);
      }
    }
  }

  return result;
}
