export const inclusions = [
  "Dupatta",
  "Fabric",
  "NA",
  "Shirt",
  "Shirt & Dupatta",
  "Shirt & Dupatta & Salwar",
  "Shirt & Salwar",
];

export const styles = [
  "2 Piece Fabric Set for Suit (Ladies)",
  "3 Piece Fabric Set for Suit (Ladies)",
  "Fabric for Dupatta",
  "Fabric for Shirt",
  "Fabric for Bottom",
  "Fabric",
  "NA",
];

export const fabrics = [
  "Banarasi",
  "Cambric",
  "Chanderi",
  "Chanderi Noil",
  "Chiffon",
  "Cotton (Bengal)",
  "Cotton (Glazed)",
  "Cotton (Plain)",
  "Crepe",
  "Georgette",
  "Georgette (Double)",
  "Ikat",
  "Khadi",
  "Khadi (Jamdani)",
  "Kota",
  "Lawn",
  "Linen",
  "Lizi Bizi",
  "Maheshwari",
  "Malmal",
  "Munga",
  "NA",
  "Net",
  "Organza",
  "Shantoon",
  "Tissue",
  "Tussar",
  "Velvet",
  "Voile (Swiss)",
  "Voile (Terry)",
];

export const kinds = [
  "Bandhej",
  "Batik",
  "Block Printed",
  "Chikan",
  "Crochia",
  "Dabu Printed",
  "Digital Printed",
  "Digital Printed Panels",
  "Embroidered",
  "Hand Painted",
  "Hand Woven",
  "Jacquard",
  "Kalamkari",
  "Leheriya",
  "NA",
  "Plain",
  "Screen Printed",
  "Self",
  "Shaded",
  "Shibori",
  "Tanchoi",
  "Tie Dyed",
  "Vegetable Dyed",
  "Vegetable Printed",
];

export const va = [
  "Block Print",
  "Chikan",
  "Crochia",
  "Digital Print",
  "Embroidery",
  "Gold Border",
  "Gold Khadi",
  "Gota Patti",
  "Hand Paint",
  "Kantha",
  "Mokaish",
  "NA",
  "Screen Print",
  "Sequins",
  "Shaded",
  "Silver Khadi",
  "Tussles",
  "Tye Dye",
  "Zari Border",
];

export const base = [
  "Cotton",
  "Cotton Polyester Blend",
  "Cotton Silk",
  "Linen",
  "Modal",
  "Modal Polyester Blend",
  "NA",
  "Rayon",
  "Silk",
];

export const width = [
  "36 inch",
  "40 inch",
  "44 inch",
  "54 inch",
  "56 inch",
  "NA",
];

export const length = [
  "1.5 m",
  "1.75 m",
  "2.0 m",
  "2.25 m",
  "2.5 m",
  "2.75 m",
  "3.0 m",
  "3.25 m",
  "3.5 m",
  "NA",
];

export const maintenance = ["Dry Clean", "Hand Wash", "Machine Wash", "NA"];
